var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('h1',{staticClass:"text-left font-medium-5 font-weight-bolder mb-2"},[_vm._v(" Pricing Tiers ")]),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumnsPriceTiers,"create-page-url":{ name: 'settings-catalog-price-tiers-create' },"back-to-list-path":"settings-catalog-price-list","table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/trashList"),
    }},on:{"onChoose":_vm.onChoose},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-end mr-1"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],class:data.is_default ? 'd-none' : 'lightIcon cursor-pointer border-dotted featherIcon',attrs:{"icon":"LTrashIcon","size":"16","title":_vm.$t('Delete')},on:{"click":function($event){return _vm.remove(data)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"featherIcon cursor-pointer border-dotted",attrs:{"title":_vm.$t('Set to active'),"icon":"LLoadIcon","size":"24"},on:{"click":function($event){return _vm.restoreContact(data.item.id)}}})],1)]}},{key:"cell(status)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.is_active ? 'Active' : 'Inactive')+" ")]}},{key:"cell(default)",fn:function(ref){
    var data = ref.data;
return [(data.is_active)?_c('div',{staticClass:"ml-2"},[_c('span',{staticClass:"cursor-pointer d-flex align-items-center position-relative",staticStyle:{"width":"max-content","height":"20px","opacity":"0.9"},style:({pointerEvents: _vm.isLoadingOfSetDisabled ? 'none':'all'}),attrs:{"id":data.id},on:{"~click":function($event){return _vm.setDefault(data)}}},[_c('b-form-group',{staticClass:"ml-2 custom-control-group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio',{staticClass:"mt-1",attrs:{"aria-describedby":ariaDescribedby,"name":("some-radios-" + (data.id)),"value":true,"size":"lg"},model:{value:(data.is_default),callback:function ($$v) {_vm.$set(data, "is_default", $$v)},expression:"data.is_default"}})]}}],null,true)}),(_vm.isSettling(data.id))?_c('b-spinner',{staticClass:"ml-1 position-absolute",staticStyle:{"margin-bottom":"4px","left":"15px","top":"4px"},attrs:{"small":"","label":"Small Spinner","type":"grow","variant":"success"}}):_vm._e()],1)]):_vm._e()]}},{key:"filter",fn:function(){return [_vm._v(" "+_vm._s('')+" ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }